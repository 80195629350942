import {Component, Input, OnInit} from '@angular/core';
import {SurveyService} from "../../../services/surveys.service";
import {TranslateService} from "@ngx-translate/core";
import {ScriptLoaderService} from "angular-google-charts";
import {ResponseTypesService} from "../../../services/config/response-types.service";
import {MatDialog} from "@angular/material/dialog";
import {UserService} from "../../../services/user.service";
import {ToastService} from "../../../services/general/toast.service";
import {AiService} from "../../../services/ai.service";
import {lowerCase} from "lodash";

@Component({
  selector: 'app-single-question-ai-analysis',
  templateUrl: './single-question-ai-analysis.component.html',
  styleUrls: ['./single-question-ai-analysis.component.scss']
})
export class SingleQuestionAiAnalysisComponent implements OnInit {
  @Input() type!: any;
  @Input() object!: any;
  @Input() question!: any;

  constructor(
    public surveyService: SurveyService,
    public translateService: TranslateService,
    private loaderService: ScriptLoaderService,
    private responsesTypeService: ResponseTypesService,
    private dialog: MatDialog,
    private userService: UserService,
    private toast: ToastService,
    private aiService: AiService,
  ) {
  }

  loading = false;
  analysis = null;
  model = null;

  ngOnInit(): void {
    switch (this.type) {
      case 'survey':
        this.model = {
          type: 'SURVEY',
          survey_title: this.object.title,
          survey_description: this.object.description,
          organization_id: this.object.organization_id,
          question_title: this.question.question,
          question_description: this.question.description,
          question_typology: this.question.typology,
          question_responses: JSON.stringify(
            this.question?.responses?.map((response) => {
              return {
                feedback: response.status,
                confidence: response.customs?.confidence,
                answer: response.answer,
                status: response.status,
                attachments: response.attachments,
              }
            })
          ),
        };
        break
      case 'decision_table':
        const proposal = this.object?.proposals?.find((proposal) => proposal?.criteria?.find((criterion) => criterion?.id === this.question?.id))
        // console.log(this.question);
        this.model = {
          type: 'DECISION_TABLE',
          decision_table_title: this.object.decision_name,
          decision_table_description: JSON.stringify(this.object.problem_statement),
          decision_table_statement_title: this.question?.title,
          decision_table_statement_description: this.question?.value,
          decision_table_new_or_reviewed: proposal?.evaluation_session,
          certainty_perc: this.question?.results?.certainty_degree,
          contraddiction_perc: this.question?.results?.contraddiction_degree,
          responses: JSON.stringify(this.question?.response)
        }
        break
    }
  }


  generateAIAnalysis() {
    this.loading = true;
    this.aiService.generateSummarySingleQ(this.model, (result) => {
      this.analysis = this.cleanText(result.response);
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  copyText(text) {
    navigator.clipboard.writeText(text);
    this.toast.success(this.translateService.instant('generic.success'), this.translateService.instant('generic.text-copied-successfully'));
  }

  cleanText(text: string): string {
    return text
      .replace(/\\n/g, '\n\n')
      .replace(/\\t/g, '    ');
  }
}

import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {ProjectsService} from '../../../services/projects.service';
import {MatTableDataSource} from '@angular/material/table';
import {ProjectsData} from '../all-projects/projects.component';
import {ActivatedRoute, Router} from '@angular/router';
import {HeaderTitleService} from '../../../services/general/header-title.service';
import {ModalSurveyDetailsComponent} from '../../../modals/modal-survey-details/modal-survey-details.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {AiService} from '../../../services/ai.service';
import {forEach} from 'lodash';
import {BackendService} from '../../../services/general/backend.service';
import {error} from 'protractor';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {filter} from 'rxjs/operators';

interface GradeTable {
  authors: string;
  year: number;
  articleTitle: string;
  studyType: string;
  mainOutcome: string;
  precision: string;
  consistency: string;
  riskOfBias: string;
  gradeQuality: string;
}

export class JsonTableService {
  parseJsonData(jsonString: string): GradeTable[] {
    try {
      // Rimuove l'intestazione della tabella e parsa il JSON
      const jsonContent = jsonString.split('\n\n')[1];
      return JSON.parse(jsonContent) as GradeTable[];
    } catch (error) {
      console.error('Errore nel parsing JSON:', error);
      return [];
    }
  }
}

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) biblioSearchSort!: MatSort;
  @ViewChild(MatPaginator) biblioPaginator!: MatPaginator;

  constructor(public userService: UserService,
              private projectService: ProjectsService,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private headerTitleService: HeaderTitleService,
              private router: Router,
              private translateService: TranslateService,
              private aiService: AiService,
              private backend: BackendService
  ) {
    this.biblioSearchDataSource = new MatTableDataSource<GradeTable>([]);
  }

  keywords: string = "";
  keywordsForChipList: string = "";
  biblioTable: object | null = null;
  question = "";
  patients: string = "";
  intervention: string = "";
  comparison: string = "";
  outcomes: string = "";
  // patients: string = "Pazienti adulti con ipertensione";
  // intervention: string = "Assunzione di farmaci anti-ipertensivi";
  // comparison: string = "Cambiamento dello stile di vita (dieta e attività fisica)";
  // outcomes: string = "Riduzione della pressione arteriosa";

  loading = false;
  project_id = null;
  project = null;
  projectFieldUpdatable = ['title', 'description'];
  projectUpdateModel = {
    title: '',
    description: '',
  };

  picoQuestionStatus: string | null = null;
  biblioSearchStatus: string | null = null;
  biblioSearchDisplayedColumns: string[] = ['authors', 'year', 'articleTitle', 'studyType', 'mainOutcome', 'precision', 'consistency', 'riskOfBias', 'gradeQuality'];
  biblioSearchDataSource: MatTableDataSource<GradeTable>;

  pubmedResults: any = {};
  pubmedResultsCached: any = {};

  searchNumItems = null;

  processStatus$ = this.aiService.processStatus$;
  isProcessing = false;
  currentProcessId: number | null = null;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.project_id = params.project_id;
      this.init();
    });

    this.aiService.processStatus$.pipe(
        filter(process => process?.status === 'completed' && process?.result !== null)
    ).subscribe(process => {
        // console.log(process);
        if (process?.result) {
          // console.log(process?.result);
          const parsedResponse = JSON.parse(process.result.response);
          this.biblioSearchDataSource.data = parsedResponse.tabella;
          this.biblioSearchDataSource.sort = this.biblioSearchSort;
          this.biblioSearchStatus = null;
          // this.dataSource.data = process.result;  // Assuming result contains your table data
        }
    });

    this.aiService.processStatus$.pipe(
        filter(process => process?.status === 'failed')
    ).subscribe(process => {
        // console.log(process);
        this.biblioSearchStatus = null;
    });
  }

  ngOnDestroy() {
    this.aiService.stopPolling();
  }

  public init() {
    this.getProject();
  }

  public getProject() {
    this.loading = true;
    this.projectService.getProject(this.project_id, (result) => {
      this.project = result;

      this.headerTitleService.setTitle(
        this.translateService.instant('generic.table') + ': ' + result.title);
      this.setUpdateProjectModel();
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  generatePICOQuestion(page: number = 1) {
    this.picoQuestionStatus = 'pending';
    const data = {
      organization_id: this.project.organization_id,
      picoStructure: {
        patients: this.patients,
        intervention: this.intervention,
        comparison: this.comparison,
        outcomes: this.outcomes
      }
    };

    // console.log(data);

    this.aiService.generatePICOResponse(data, result => {
        this.loading = false;

        if (result) {
          let jsonString = result.response.replace(/`/g, '').trim();
          const jsonObject = JSON.parse(jsonString);
          this.keywords = jsonObject.keywords;
          this.keywordsForChipList = this.keywords;
          this.question = jsonObject.question;
        }
        this.picoQuestionStatus = null;
      },
      error => {
        console.log(error);
        this.picoQuestionStatus = null;
      }
    );

  }

  /**
   * Cerca un determinato valore su pubmed
   * @param query
   */
  searchTermInPublmed(page: number = 1) {

    let results = [];

    this.loading = true;
    this.biblioSearchStatus = 'pending';

    const query = this.keywords.toString();

    //  restituisco i risultati cachati se ci sono
    // if (this.pubmedResultsCached[page]) {
    //   console.log("cached");
    //   this.pubmedResults = this.pubmedResultsCached[page];
    //   this.pubmedResults.articles.map(a => {
    //     let article = {
    //       title: '',
    //       authors: '',
    //       PMID: '',
    //       Doid: '',
    //       pubyear: '',
    //       pubmonth: '',
    //       pubday: '',
    //       pii: '',
    //       ISSN: '',
    //       volume: '',
    //       link: '',
    //     };
    //
    //     article.title = a.ArticleTitle;
    //     article.authors = a.Authors;
    //     article.pubyear = a.PubYear;
    //     article.pubmonth = a.PubMonth;
    //     article.pubday = a.PubDay;
    //     article.PMID = a.PMID;
    //     article.ISSN = a.ISSN;
    //     article.Doid = a.Doid;
    //     article.pii = a.Pii;
    //     article.volume = a.Volume;
    //     article.link = a.link;
    //
    //     results.push(article);
    //
    //   });
    //
    //   const data = {
    //     organization_id: this.project.organization_id,
    //     statement: results,
    //     question: this.question
    //   };
    //
    //   this.aiService.generateLiteratireReviewTableWithPubmedArticles(data, (result) => {
    //     this.loading = false;
    //     this.biblioTable = result.response;
    //     this.biblioSearchStatus = 'ready';
    //   });
    //
    //   return;
    // }

    this.backend.post(`tools/pubmed/search`, {term: query, page: page}).subscribe((result) => {
      this.pubmedResultsCached[page] = result;

      this.searchNumItems = parseInt(this.pubmedResultsCached[page]?.total_items);

      if (!this.searchNumItems) {
        this.biblioSearchStatus = null;
        return;
      }

      this.pubmedResults = this.pubmedResultsCached[page];

      this.pubmedResults.articles.map(a => {
        let article = {
          title: '',
          authors: '',
          PMID: '',
          Doid: '',
          pubyear: '',
          pubmonth: '',
          pubday: '',
          pii: '',
          ISSN: '',
          volume: '',
          link: '',
        };

        article.title = a.ArticleTitle;
        article.authors = a.Authors;
        article.pubyear = a.PubYear;
        article.pubmonth = a.PubMonth;
        article.pubday = a.PubDay;
        article.PMID = a.PMID;
        article.ISSN = a.ISSN;
        article.Doid = a.Doid;
        article.pii = a.Pii;
        article.volume = a.Volume;
        article.link = a.link;

        results.push(article);

      });

      // console.log(results);

      const data = {
        organization_id: this.project.organization_id,
        statement: results,
        question: this.question
      };

      this.aiService.generateLiteratireReviewTableWithPubmedArticles(data, (result) => {
          this.loading = false;

          this.currentProcessId = result.process_id;

          // const parsedResponse = JSON.parse(result.response);
          //
          // this.biblioTable = parsedResponse;
          // // Aggiorna il data source con i nuovi dati
          // // this.biblioSearchDataSource = new MatTableDataSource(parsedResponse);
          // this.biblioSearchDataSource.data = parsedResponse.tabella;
          //
          // // Imposta il sort
          // this.biblioSearchDataSource.sort = this.biblioSearchSort;

          this.aiService.startPolling(result.process_id);
          // this.biblioSearchStatus = null;

        },
        (error) => {
          this.biblioSearchStatus = null;
        }
      );

    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      this.loading = false;
      this.biblioSearchStatus = null;
    })
  }

  public setUpdateProjectModel(reverse = false) {

    if (!reverse) {
      this.projectUpdateModel = {...this.project};
      // const description = this.projectUpdateModel.description;

      // const data = {
      //   organization_id: this.project.organization_id,
      //   statement: description,
      // };

      // this.aiService.generateKeywordsResponse(data, (result) => {
      //   this.loading = false;
      //   this.keywords = result.response;
      //
      // });

    } else {
      for (const field of this.projectFieldUpdatable) {
        this.project[field] = this.projectUpdateModel[field];
      }
    }
  }


  public get projectUpdateModelIsChanged() {
    for (const field of this.projectFieldUpdatable) {
      if (this.projectUpdateModel[field] !== this.project[field]) {
        return true;
      }
    }
    return false;
  }

  public updateProject() {
    this.loading = true;

    const model = this.projectFieldUpdatable.reduce((acc, field) => {
      acc[field] = this.projectUpdateModel[field];
      return acc;
    }, {});


    this.projectService.updateProject(this.project_id, model, (result) => {
      this.setUpdateProjectModel(true);
      this.headerTitleService.setTitle('Project: ' + result.title);
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  retryProcess() {
    if (this.currentProcessId) {
      this.searchTermInPublmed();
    }
  }
}


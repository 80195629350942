import {Injectable} from '@angular/core';
import {BackendService} from './general/backend.service';
import {BehaviorSubject, Observable, Subscription, timer, map} from 'rxjs';
import {BedrockProcess} from './BedrockProcess';
import { switchMap, takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AiService {
  constructor(private backend: BackendService) {}

  private processStatusSubject = new BehaviorSubject<BedrockProcess | null>(null);
  public processStatus$ = this.processStatusSubject.asObservable();
  private pollingSubscription?: Subscription;


  /**
   * invia richiesta al modello AI
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public generateMagicWriterResponse(
    model: any,
    success_callback = (data: any) => {},
    error_callback = (error: any) => {}
  ) {
    this.backend.post('ai/generate-magic-writer-response', model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * invia richiesta al modello AI
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public generateSummarySingleQ(
    model: any,
    success_callback = (data: any) => {},
    error_callback = (error: any) => {}
  ) {
    this.backend.post('ai/generate-summary-single-question', model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public textTranslateResponse(
    model: any,
    success_callback = (data: any) => {},
    error_callback = (error: any) => {}
  ) {
    this.backend.post('ai/text-translate', model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * invia richiesta al modello AI
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public dashboardExplanation(
    model: any,
    success_callback = (data: any) => {},
    error_callback = (error: any) => {}
  ) {
    this.backend.post('ai/dashboard-explanation', model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * invia richiesta al modello AI
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public generateKeywordsResponse(
    model: any,
    success_callback = (data: any) => {},
    error_callback = (error: any) => {}
  ) {
    this.backend.post('ai/generate-keywords-response', model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * invia richiesta al modello AI
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public generatePICOResponse(
    model: any,
    success_callback = (data: any) => {},
    error_callback = (error: any) => {}
  ) {
    this.backend.post('ai/generate-pico-response', model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * invia richiesta al modello AI
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public generateLiteratireReviewTableWithPubmedArticles(
    model: any,
    success_callback = (data: any) => {},
    error_callback = (error: any) => {}
  ) {
    this.backend.post('ai/generate-literature-ai-response', model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  getPicoGradeProcessStatus(processId: number): Observable<BedrockProcess> {
    return this.backend.get(`ai/generate-literature-ai-response/status/${processId}`).pipe(
      map((response: any): BedrockProcess => {
        return {
          id: response.id,
          status: response.status,
          message: response.message,
          result: response.result,
          created_at: response.created_at,
          updated_at: response.updated_at
        };
      })
    );
  }

  startPolling(processId: number, intervalMs: number = 5000) {
    this.stopPolling();

    this.pollingSubscription = timer(0, intervalMs).pipe(
      switchMap(() => this.getPicoGradeProcessStatus(processId)),
      takeWhile((response: BedrockProcess) =>
        response.status !== 'completed' &&
        response.status !== 'failed',
        true
      )
    ).subscribe(
      (process) => {
        this.processStatusSubject.next(process);
        if (process.status === 'completed' || process.status === 'failed') {
          this.stopPolling();
        }
      },
      (error) => {
        console.error('Error polling process status:', error);
        this.stopPolling();
      }
    );
  }

  stopPolling() {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
      this.pollingSubscription = undefined;
    }
  }

  ngOnDestroy() {
    this.stopPolling();
  }
}

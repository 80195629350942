import {Component, NgZone, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ModalPrivacyPolicyComponent} from 'src/app/modals/modal-privacy-policy/modal-privacy-policy.component';
import {ConfigService} from 'src/app/services/config.service';
import {HeaderTitleService} from 'src/app/services/general/header-title.service';
import {ToastService} from 'src/app/services/general/toast.service';
import {UserService} from 'src/app/services/user.service';
import swal, { SweetAlertIcon } from 'sweetalert2';
import value from '*.json';

@Component({
  selector: 'app-bootstrap',
  templateUrl: './bootstrap.component.html',
  styleUrls: ['./bootstrap.component.scss']
})
export class BootstrapComponent implements OnInit {

  loadCompleteDefaultPath = '/home';
  returnUrl: any;
  returnUrlParams: any;

  loadingValue = 0;
  loadingMaxValue = 1;
  loadingStep = 2;
  loadingError = false;
  loginError = false;
  error: any;

  constructor(private route: ActivatedRoute,
              private userService: UserService,
              private configService: ConfigService,
              private router: Router,
              private ngZone: NgZone,
              private toast: ToastService,
              private title: HeaderTitleService,
              private translateService: TranslateService,
              private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {

    this.returnUrl = this.route.snapshot.queryParams.returnUrl;
    this.error = null;
    this.loadingError = false;
    this.loadingValue = 0;

    this.incrementLoading();

    this.userService.getUser((user) => {
      const current_org_usr = this.userService.getCurrentOrganizationUser();
      const customs  = current_org_usr?.customs;

      const handleConfigService = () => {
        this.incrementLoading();
        this.configService.getConfig(() => {
          this.incrementLoading();
        });
      };

      const showOrderNumberAlert = async () => {
        const inputValue = '';
        const { value: input_value } = await swal.fire({
          title: this.translateService.instant('swal.alexion.order-number.title'),
          input: 'text',
          inputLabel: this.translateService.instant('swal.alexion.order-number.input-label'),
          inputValue,
          confirmButtonText: this.translateService.instant('generic.send'),
          confirmButtonColor: '#9169DB',
          allowOutsideClick: false,
          allowEscapeKey : false,
          inputValidator: (value) => {
            if (!value) {
              return this.translateService.instant('swal.errors.field-mandatory');
            }
            if (value !== customs?.order_number) {
              return this.translateService.instant('swal.errors.order-number-wrong');
            }
          }
        });

        if (customs?.order_number === input_value) {
          localStorage.setItem('order_number_alexion_logged', '1');
          window.location.reload()
        } else {
          showOrderNumberAlert();
        }
      };

      if (customs?.profile === 'alexion' && customs?.order_number && !JSON.parse(localStorage.getItem('order_number_alexion_logged')))  {
        showOrderNumberAlert();
      } else {
        handleConfigService();
      }
    }, (error) => {
      if (error.status === 401) {
        // this.router.navigate(['/login']);
        this.loginError = true;
        this.title.setTitle(this.translateService.instant('routing.title-user-not-logged'));
      } else {
        this.error = error;
        this.loadingError = true;
        this.toast.error('Butterfly Decisions', this.translateService.instant('User', 'pages.bootstrap.error-contact-customer-service'));
      }
    });
  }


  bootApp() {
    this.loadComplete();
  }

  incrementLoading() {

    this.ngZone.run(() => {
      this.loadingValue += this.loadingMaxValue / (this.loadingStep - 1);

      if (this.loadingValue > this.loadingStep) {
        this.bootApp();
      }
    });
  }


  loadComplete() {
    this.toast.info('Butterfly Decisions', this.translateService.instant('pages.bootstrap.info-ready-service'));

    this.loadingValue = this.loadingMaxValue;

    this.ngZone.run(() => {

      this.userService.resetCurrentLanguage();

      this.navigate();
    });
  }

  login() {
    this.userService.loginUser();
  }

  navigate() {
    if (this.returnUrl) {
      this.router.navigate([this.returnUrl]);
    }
    else {
      this.router.navigate([this.loadCompleteDefaultPath]);
    }
  }

  // register() {}

}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OrganizationService} from "../../services/organization.service";
import {ConfigService} from "../../services/config.service";
import {MatTableDataSource} from "@angular/material/table";
import {FormControl, NgForm} from "@angular/forms";
import {UserTypeService} from "../../services/config/user-types.service";
import { UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/services/general/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { AiService } from '../../services/ai.service';

// import {proposeRevision} from './static-json/proposeRevision.js';
import {proposeRevision, rewriteStatement, rewriteStatementBeforeAnswers } from './static-json/prompt.js';
// import {rewriteStatement} from './static-json/rewriteStatement.js';
// import {rewriteStatementBeforeAnswers} from './static-json/rewriteStatementBeforeAnswers.js';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Clipboard } from '@angular/cdk/clipboard';
import {CriteriaService} from '../../services/config/criteria.service';
import {ResponseTypesService} from '../../services/config/response-types.service';

@Component({
  selector: 'app-modal-magic-writer',
  templateUrl: './modal-magic-writer.component.html',
  styleUrls: ['./modal-magic-writer.component.scss']
})
export class ModalMagicWriterComponent implements OnInit {
  displayedColumns: string[] = [
    // 'name',
    'sentiment',
    'feedback',
    'truefalse',
    'confidence',
    // 'evidence',
    // 'attachment_events',
    'select'
  ];

  displayedColumnsFileAttachments: string[] = [
    'file',
    'url',
    'select'
  ];

  displayedColumnsPubmedAttachments: string[] = [
    'pubmed',
    'url',
    'select'
  ];
  usersData: any = new MatTableDataSource([]);

  constructor(
    public dialogRef: MatDialogRef<ModalMagicWriterComponent>, @Inject(MAT_DIALOG_DATA) public data: any = {},
    private organizationService: OrganizationService, private configService: ConfigService,
    private userTypeService: UserTypeService, private userService: UserService,
    private toast: ToastService,
    private translateService: TranslateService,
    private aiService: AiService,
    private clipboard: Clipboard,
    private sanitizer: DomSanitizer,
    private criteriaService: CriteriaService,
    private responsesTypeService: ResponseTypesService,
  ) {
  }

  protected readonly proposeRevision = proposeRevision;
  protected readonly rewriteStatement = rewriteStatement;
  protected readonly rewriteStatementBeforeAnswers = rewriteStatementBeforeAnswers;


  copySuccess: boolean = false;
  copySuccessMessage: string = 'Text copied successfully!';
  disabledCopyButton: boolean = true;

  response: string;
  selectedPrompt: string;
  renderedHTML: SafeHtml = '';

  loading: boolean = false;
  criterio: any;
  organizationId: string;

  ngOnInit(): void {
    this.criterio = JSON.parse(JSON.stringify(this.data)).criterio;
    this.organizationId = JSON.parse(JSON.stringify(this.data)).organization_id;
    this.selectedPrompt = this.proposeRevision;
  }

  getCriteriaService() {
    return this.criteriaService;
  }

  getResponsesTypeService() {
    return this.responsesTypeService;
  }

  replacePlaceholders(template, values) {
    return template.replace(/\{(\w+)\}/g, (match, key) => {
      return values.hasOwnProperty(key) ? values[key] : match;
    });
  }

  async processTitle(title: any[]): Promise<string> {
      let res: string;

      try {
        const result = await new Promise<any>((resolve, reject) => {
          this.aiService.textTranslateResponse(
            title,
            (result) => resolve(result),
            (error) => reject(error)
          );
        });

        if (result.response.indexOf("NaN") !== -1) {
          res = `${title}\n`;
        } else {
          res = `${result.response.replace('translated_text: ', '')}\n`;
        }
      } catch (error) {
        console.error("Error translating title:", error);
      }

      return res;

    }

  async processFeedbacks(selectedFeedback: any[]): Promise<string> {
    let feedbacks = "";

    for (const f of selectedFeedback) {
      const feedback = f[0];
      try {
        const result = await new Promise<any>((resolve, reject) => {
          this.aiService.textTranslateResponse(
            feedback.feedback,
            (result) => resolve(result),
            (error) => reject(error)
          );
        });

        if (result.response.indexOf("NaN") !== -1) {
          feedbacks += `${feedback.feedback}\n with their respective agreements: ${feedback.consent_value}\nand certainty degree:\n${feedback.confidence}\n`;
        } else {
          feedbacks += `${result.response.replace('translated_text: ', '')}\n with their respective agreements: ${feedback.consent_value}\nand certainty degree:\n${feedback.confidence}\n`;
        }
      } catch (error) {
        console.error("Error translating feedback:", error);
        // You might want to handle this error case, perhaps by using the original feedback
        feedbacks += `${feedback.feedback}\n with their respective agreements: ${feedback.consent_value}\nand certainty degree:\n${feedback.confidence}\n`;
      }
    }

    return feedbacks;
  }

  async handleFeedbacks(selectedFeedback:any) {
    try {
      // const title = await this.processTitle(this.criterio.value);

      // const feedbacks = await this.processFeedbacks(selectedFeedback);

      const values = {
        // knowledge: "-knowledge-",
        // context: "",
        // question: title,
        // text: feedbacks
        question: this.criterio.value,
        text: selectedFeedback
      };

      this.loading = true;

      let data = {};
      let jsonToSend = {
        role: '',
        content: '',
        type: ''
      };

      if (this.selectedPrompt === proposeRevision) {
        jsonToSend = proposeRevision;
      }
      else if (this.selectedPrompt === rewriteStatementBeforeAnswers) {
        jsonToSend = rewriteStatementBeforeAnswers;
      }
      else if (this.selectedPrompt === rewriteStatement) {
        jsonToSend = rewriteStatement;
      }

      const allText = this.replacePlaceholders(jsonToSend.content, values);

      data = {...data, content: allText, organization_id: this.organizationId};

      this.aiService.generateMagicWriterResponse(data, (result) => {
        this.loading = false;
        this.response = result.response;
        this.updateHTML();
        this.disabledCopyButton = false;
      });
    } catch (error) {
      console.error("Error processing feedbacks:", error);
    }
  }

  onSubmit(form: NgForm) {

    const selectedFeedback = this.criterio.response
      .filter(feedback => feedback.checked)
      .map(feedback => [
        {
          'feedback': feedback.feedback,
          'consent_value': feedback.consent_value,
          'confidence': feedback.confidence,
          'sentiment_magnitude': feedback.sentiment_magnitude,
          'sentiment_score': feedback.sentiment_score,
        }]);


    this.handleFeedbacks(selectedFeedback);
  }

  copyText(text: string) {
    this.clipboard.copy(text);
    this.copySuccess = true;
    this.closeDialogue();

    // Clear the success message after 2 seconds
    setTimeout(() => {
      this.copySuccess = false;
    }, 2000);
  }

  closeDialogue(event: any = null) {
    this.dialogRef.close({event: event});
  }

  onSelectionChange(event: any): void {
    this.selectedPrompt = event.value;
  }

  updateHTML() {
    const html = this.convertMarkdownToHTML(this.response);
    this.renderedHTML = this.sanitizer.bypassSecurityTrustHtml(html);
  }
  private convertMarkdownToHTML(markdown: string): string {
    // This is a very basic conversion. You'd want to expand this for a full Markdown implementation.
    let html = markdown
      .replace(/#{1,6}\s?([^\n]+)/g, (match, p1, offset, string) => {
        const level = match.trim().startsWith('######') ? 6 :
                      match.trim().startsWith('#####') ? 5 :
                      match.trim().startsWith('####') ? 4 :
                      match.trim().startsWith('###') ? 3 :
                      match.trim().startsWith('##') ? 2 : 1;
        return `<h${level}>${p1}</h${level}>`;
      })
      .replace(/(\*\*|__)(.*?)\1/g, '<strong>$2</strong>')
      .replace(/(\*|_)(.*?)\1/g, '<em>$2</em>')
      .replace(/\n/g, '<br>');
    return html;
  }

}


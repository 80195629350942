<!-- Blocco 1 -->
<div class="container-fluid accordion-container">
  <div class="container">
    <mat-accordion class="accordion" multi>

      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">1 </span>
            {{ 'pages.task-management.component.assigned-actions' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container *ngIf="filters?.section_filtered?.length">
          <div class="row">
            <div class="col-12 col-lg-6 px-0 mb-0">
              <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                <mat-label class="box-title px-3 mb-0">{{ 'generic.filters' | translate }}</mat-label>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <mat-form-field class="select-primary col-12" appearance="fill">
              <mat-label>{{ 'generic.section-filter' | translate }}</mat-label>
              <mat-select name="filter_section" [(ngModel)]="filters.section_selected"
                          (selectionChange)="filterOnChange()">
                <mat-option value="" selected></mat-option>
                <mat-option *ngFor="let sec of filters.section_filtered" [value]="sec">{{ sec }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>


        <!-- Pulsante di aggiunta Task -->
        <div class="container p-4" *ngIf="decisionPlan?.id && !createTaskActive">
          <div class="row">
            <button class="col-12 big-button" mat-raised-button (click)="addTask()">
              <i class="icon-big_add"></i>
              {{ 'components.management-plan.add-task' | translate }}
            </button>
          </div>
        </div>

        <ng-container *ngFor="let task of tasksToComplete; let i = index">
          <div class="row" *ngIf="task.show && task.show == true">
            <div class="col-12 px-2">
              <div class="box px-0 box-two-row py-3" [class]="task.editMode == true ? 'border-modify': ''">
                <mat-label class="box-title px-3" *ngIf="!task.editMode">
                  <ng-container *ngIf="isAlexion(); else elseBlock">
                    {{ "pages.task-management." + task.additional_text | translate}}
                    <ng-container *ngIf="task.additional_text == 'therapy'; else elseBlockTherapy">
                       - {{ "pages.task-management." + task.customs.therapy_type | translate}}
                    </ng-container>
                    <ng-template #elseBlockTherapy>
                      - {{ "generic.session" | translate}} {{ task.customs.session.evaluation_session }}
                    </ng-template>
                  </ng-container>
                  <ng-template #elseBlock>
                    {{ task.additional_text }}
                  </ng-template>
                </mat-label>
                <mat-form-field class="pb-2 mx-2 select-primary col-12" appearance="fill" *ngIf="task.editMode">
                  <mat-label *ngIf="!isAlexion()">{{ 'generic.level' | translate }}</mat-label>
                  <mat-label *ngIf="isAlexion()">{{ 'generic.type' | translate }}</mat-label>
                  <mat-select [(ngModel)]="task.additional_text" required>
                    <mat-option [value]="option.value"
                                *ngFor="let option of additional_text_options">{{ option.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <ng-container *ngIf="isAlexion() && task.additional_text === 'visit'">
                  <mat-form-field class="pb-2 mx-2 select-primary col-12" appearance="fill" *ngIf="task.editMode">
                    <mat-label>{{ 'generic.session' | translate }}</mat-label>
                    <mat-select [(ngModel)]="task.customs.session" required>
                      <mat-option [value]="option.value"
                                  *ngFor="let option of sessions_option">{{ option.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>

                <ng-container *ngIf="isAlexion() && task.additional_text === 'therapy'">
                  <mat-form-field class="pb-2 mx-2 select-primary col-12" appearance="fill" *ngIf="task.editMode">
                    <mat-label>{{ 'generic.therapy-type' | translate }}</mat-label>
                    <mat-select [(ngModel)]="task.customs.therapy_type" required>
                      <mat-option [value]="option.value"
                                  *ngFor="let option of therapy_options">{{ option.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>

                <mat-form-field class="ml-2 input-primary col-12" *appHideIfOrganization="'alexion,enel'">
                  <input matInput placeholder="{{'generic.description' | translate}}" [(ngModel)]="task.description"
                         [readonly]="!task.editMode" required>
                </mat-form-field>
                <mat-form-field class="pb-2 select-primary col-12" appearance="fill"
                                *ngIf="currentOrganization?.customs?.profile == 'enel'">
                  <mat-label>{{ 'pages.task-management.component.description' | translate }}</mat-label>
                  <mat-select class="color-default" [(ngModel)]="task.description" [disabled]="!task.editMode" required>
                    <mat-option *ngFor="let sec of allSections" [value]="sec">{{ sec }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="pb-2 select-primary col-12" appearance="fill"
                                *ngIf="currentOrganization?.customs?.profile == 'enel'">
                  <mat-label>{{ 'pages.task-management.component.itemid' | translate }}</mat-label>
                  <mat-select class="color-default" [(ngModel)]="task.item_id" [disabled]="!task.editMode"
                              [required]="task.additional_text == 'level project'">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let itid of filerItemBySection(task.description)"
                                [value]="itid.id">{{ itid.text }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="mx-2 input-primary col-12" appearance="fill" *ngIf="!isAlexion() || (isAlexion() && task.additional_text === 'therapy')">
                  <mat-label>{{ 'pages.task-management.component.action' | translate }}</mat-label>
                  <textarea matInput [(ngModel)]="task.action" [readonly]="!task.editMode" required></textarea>
                </mat-form-field>
                <ng-container *ngIf="isAlexion(); else elseBlock">
                  <mat-form-field class="mx-2 input-primary col-12 my-2" appearance="fill">
                    <textarea matInput placeholder="{{'generic.note' | translate}}"
                              [(ngModel)]="task.customs.note" [readonly]="!task.editMode"></textarea>
                  </mat-form-field>
                </ng-container>
                <ng-template #elseBlock>
                  <mat-form-field class="mx-2 input-primary col-4 my-2">
                    <input matInput placeholder="{{'pages.task-management.component.responsible' | translate}}"
                           [(ngModel)]="task.assigned_user_name" [readonly]="!task.editMode">
                  </mat-form-field>
                </ng-template>

                <ng-container *ngIf="isAlexion() && task.additional_text === 'visit'">
                  <mat-form-field class="datepicker-primary col-4" appearance="fill" (click)="visit_date.open()">
                    <mat-label>{{ 'pages.task-management.component.visit-date' | translate }}</mat-label>
                    <input matInput [matDatepicker]="visit_date" [readonly]="!task.editMode" [(ngModel)]="task.customs.visit_date"
                           class="color-default ng-trim-ignore" disabled>
                    <mat-datepicker-toggle matSuffix [for]="visit_date">
                      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #visit_date [disabled]="!task.editMode"></mat-datepicker>
                  </mat-form-field>
                </ng-container>

                <ng-container *ngIf="!isAlexion() || (isAlexion() && task.additional_text === 'therapy')">
                  <mat-form-field class="datepicker-primary col-4" appearance="fill" (click)="start_date.open()"
                                  *ngIf="currentOrganization?.customs?.profile != 'enel'">
                    <mat-label>{{ 'pages.task-management.component.start-date' | translate }}</mat-label>
                    <input matInput [matDatepicker]="start_date" [readonly]="!task.editMode" [(ngModel)]="task.start_date"
                           class="color-default ng-trim-ignore" disabled>
                    <mat-datepicker-toggle matSuffix [for]="start_date">
                      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #start_date [disabled]="!task.editMode"></mat-datepicker>
                  </mat-form-field>
                  <ng-container *ngIf="isAlexion() && task.id && !task.editMode && !task.end_date; else elseBlockEndDate">
                    <span class="label-box font-weight-bold ml-5 pl-5">In corso</span>
                  </ng-container>
                  <ng-template #elseBlockEndDate>
                    <mat-form-field class="datepicker-primary col-4" appearance="fill" (click)="end_date.open()">
                      <mat-label>{{ 'pages.task-management.component.end-date' | translate }}</mat-label>
                      <input matInput [matDatepicker]="end_date" [readonly]="!task.editMode" [(ngModel)]="task.end_date"
                             class="color-default ng-trim-ignore" disabled>
                      <mat-datepicker-toggle matSuffix [for]="end_date">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #end_date [disabled]="!task.editMode"></mat-datepicker>
                    </mat-form-field>
                  </ng-template>
                </ng-container>

                <!-- <mat-checkbox class="abs-tr" name="check_completed_{{i}}" [checked]="task.has_completed == true" [disabled]="true" ></mat-checkbox> -->

                <div class="row mx-auto flex-row-reverse" *ngIf="!task.editMode">
                  <button mat-icon-button class="mr-2" color="warn" [disableRipple]="true" (click)="deleteTask(task)"
                          matTooltip="{{'tooltip.delete' | translate}}" [matTooltipPosition]="'above'">
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                  <button mat-icon-button [disableRipple]="true" (click)="modifyTask(task, true)"
                          matTooltip="{{'tooltip.edit' | translate}}" [matTooltipPosition]="'above'"><i
                    class="icon-edit main-color"></i></button>
                  <ng-container *appHideIfOrganization="'alexion'">
                    <button *ngIf="task.has_completed == true" mat-stroked-button
                            class="rounded-pill border-success text-success" (click)="checkCompletedTask(task, false)">
                      <fa-icon [icon]="faCheck"></fa-icon>
                      {{ 'generic.done' | translate }}
                    </button>
                    <button *ngIf="task.has_completed == false" mat-stroked-button class="rounded-pill"
                            (click)="checkCompletedTask(task, true)">
                      <fa-icon [icon]="faCheck"></fa-icon>
                      {{ 'generic.not-done' | translate }}
                    </button>
                  </ng-container>
                </div>
                <div class="row mx-auto flex-row-reverse" *ngIf="task.editMode">
                  <button *ngIf="task.id" mat-flat-button color="primary" class="mr-2"
                          (click)="modifyTask(task, false)">{{ 'generic.buttons.update' | translate }}
                  </button>
                  <button *ngIf="!task.id" mat-flat-button color="primary" class="mr-2"
                          (click)="saveNewTask(task)">{{ 'generic.buttons.save' | translate }}
                  </button>
                  <button *ngIf="!task.id || task.editMode" mat-flat-button color="warn" class="mr-2"
                          (click)="cancelEditing(task)">{{ 'generic.buttons.cancel-and-back' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- <div class="row justify-content-center mb-3">
            <button mat-flat-button color="primary">{{'pages.task-management.component.action-complete-start-table' | translate}}</button>
        </div> -->


      </mat-expansion-panel>

    </mat-accordion>
  </div>
</div>

<!-- Progress bar -->
<!-- <div class="row">
  <h2>Inserire la timeline dinamica</h2>
</div> -->

<!-- Blocco 2 -->
<div class="container-fluid accordion-container">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">2 </span>
            <span *ngIf="isAlexion(); else elseBlockString"><span>{{ 'generic.visits' | translate }}</span></span>
            <ng-template #elseBlockString>
              <span>{{ 'pages.task-management.component.ended-actions' | translate }}</span>
            </ng-template>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container *ngIf="!isAlexion(); else elseBlock">
          <ng-container *ngFor="let task of taskCompleted; let i = index">
            <div class="row" *ngIf="task.show && task.show == true">
              <div class="col-12 px-2">
                <div class="box px-0 box-two-row py-3">


                  <mat-label class="box-title px-3" *ngIf="!task.editMode">
                    <ng-container *ngIf="isAlexion(); else elseBlock">
                      {{ "pages.task-management." + task.additional_text | translate}}
                      <ng-container *ngIf="task.additional_text == 'therapy'; else elseBlockTherapy">
                        - {{ "pages.task-management." + task.customs.therapy_type | translate}}
                      </ng-container>
                      <ng-template #elseBlockTherapy>
                        - {{ "generic.session" | translate}} {{ task.customs.session.evaluation_session }}
                      </ng-template>
                    </ng-container>
                    <ng-template #elseBlock>
                      {{ task.additional_text }}
                    </ng-template>
                  </mat-label>
                  <mat-form-field class="pb-2 mx-2 select-primary col-12" appearance="fill" *ngIf="task.editMode">
                    <mat-label *ngIf="!isAlexion()">{{ 'generic.level' | translate }}</mat-label>
                    <mat-label *ngIf="isAlexion()">{{ 'generic.type' | translate }}</mat-label>
                    <mat-select [(ngModel)]="task.additional_text" required>
                      <mat-option [value]="option.value"
                                  *ngFor="let option of additional_text_options">{{ option.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <ng-container *ngIf="isAlexion() && task.additional_text === 'visit'">
                    <mat-form-field class="pb-2 mx-2 select-primary col-12" appearance="fill" *ngIf="task.editMode">
                      <mat-label>{{ 'generic.session' | translate }}</mat-label>
                      <mat-select [(ngModel)]="task.customs.session" required>
                        <mat-option [value]="option.value"
                                    *ngFor="let option of sessions_option">{{ option.label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="isAlexion() && task.additional_text === 'therapy'">
                    <mat-form-field class="pb-2 mx-2 select-primary col-12" appearance="fill" *ngIf="task.editMode">
                      <mat-label>{{ 'generic.therapy-type' | translate }}</mat-label>
                      <mat-select [(ngModel)]="task.customs.therapy_type" required>
                        <mat-option [value]="option.value"
                                    *ngFor="let option of therapy_options">{{ option.label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>

                  <mat-form-field class="ml-2 input-primary col-12" *appHideIfOrganization="'alexion,enel'">
                    <input matInput placeholder="{{'generic.description' | translate}}" [(ngModel)]="task.description"
                           [readonly]="!task.editMode" required>
                  </mat-form-field>
                  <mat-form-field class="pb-2 select-primary col-12" appearance="fill"
                                  *ngIf="currentOrganization?.customs?.profile == 'enel'">
                    <mat-label>{{ 'pages.task-management.component.description' | translate }}</mat-label>
                    <mat-select class="color-default" [(ngModel)]="task.description" [disabled]="!task.editMode" required>
                      <mat-option *ngFor="let sec of allSections" [value]="sec">{{ sec }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="pb-2 select-primary col-12" appearance="fill"
                                  *ngIf="currentOrganization?.customs?.profile == 'enel'">
                    <mat-label>{{ 'pages.task-management.component.itemid' | translate }}</mat-label>
                    <mat-select class="color-default" [(ngModel)]="task.item_id" [disabled]="!task.editMode"
                                [required]="task.additional_text == 'level project'">
                      <mat-option></mat-option>
                      <mat-option *ngFor="let itid of filerItemBySection(task.description)"
                                  [value]="itid.id">{{ itid.text }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="mx-2 input-primary col-12" appearance="fill" *ngIf="!isAlexion() || (isAlexion() && task.additional_text === 'therapy')">
                    <mat-label>{{ 'pages.task-management.component.action' | translate }}</mat-label>
                    <textarea matInput [(ngModel)]="task.action" [readonly]="!task.editMode" required></textarea>
                  </mat-form-field>
                  <ng-container *ngIf="isAlexion(); else elseBlock">
                    <mat-form-field class="mx-2 input-primary col-12 my-2" appearance="fill">
                    <textarea matInput placeholder="{{'generic.note' | translate}}"
                              [(ngModel)]="task.customs.note" [readonly]="!task.editMode"></textarea>
                    </mat-form-field>
                  </ng-container>
                  <ng-template #elseBlock>
                    <mat-form-field class="mx-2 input-primary col-4 my-2">
                      <input matInput placeholder="{{'pages.task-management.component.responsible' | translate}}"
                             [(ngModel)]="task.assigned_user_name" [readonly]="!task.editMode">
                    </mat-form-field>
                  </ng-template>

                  <ng-container *ngIf="isAlexion() && task.additional_text === 'visit'">
                    <mat-form-field class="datepicker-primary col-4" appearance="fill" (click)="visit_date.open()">
                      <mat-label>{{ 'pages.task-management.component.visit-date' | translate }}</mat-label>
                      <input matInput [matDatepicker]="visit_date" [readonly]="!task.editMode" [(ngModel)]="task.customs.visit_date"
                             class="color-default ng-trim-ignore" disabled>
                      <mat-datepicker-toggle matSuffix [for]="visit_date">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #visit_date [disabled]="!task.editMode"></mat-datepicker>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="!isAlexion() || (isAlexion() && task.additional_text === 'therapy')">
                    <mat-form-field class="datepicker-primary col-4" appearance="fill" (click)="start_date.open()"
                                    *ngIf="currentOrganization?.customs?.profile != 'enel'">
                      <mat-label>{{ 'pages.task-management.component.start-date' | translate }}</mat-label>
                      <input matInput [matDatepicker]="start_date" [readonly]="!task.editMode" [(ngModel)]="task.start_date"
                             class="color-default ng-trim-ignore" disabled>
                      <mat-datepicker-toggle matSuffix [for]="start_date">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #start_date [disabled]="!task.editMode"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="datepicker-primary col-4" appearance="fill" (click)="end_date.open()">
                      <mat-label>{{ 'pages.task-management.component.end-date' | translate }}</mat-label>
                      <input matInput [matDatepicker]="end_date" [readonly]="!task.editMode" [(ngModel)]="task.end_date"
                             class="color-default ng-trim-ignore" disabled>
                      <mat-datepicker-toggle matSuffix [for]="end_date">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #end_date [disabled]="!task.editMode"></mat-datepicker>
                    </mat-form-field>
                  </ng-container>

                  <div class="row mx-auto flex-row-reverse">
                    <button mat-icon-button class="mr-2" color="warn" [disableRipple]="true" (click)="deleteTask(task)"
                            matTooltip="{{'tooltip.delete' | translate}}" [matTooltipPosition]="'above'">
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                    <button mat-stroked-button class="rounded-pill border-success text-success"
                            (click)="checkCompletedTask(task, false)">
                      <fa-icon [icon]="faCheck"></fa-icon>
                      Completato
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-template #elseBlock>
          <ng-container *ngFor="let task of visits; let i = index">
            <div class="row" *ngIf="task.show && task.show == true">
              <div class="col-12 px-2">
                <div class="box px-0 box-two-row py-3" [class]="task.editMode == true ? 'border-modify': ''">
                  <mat-label class="box-title px-3" *ngIf="!task.editMode">
                    <ng-container *ngIf="isAlexion(); else elseBlock">
                      {{ "pages.task-management." + task.additional_text | translate}}
                      <ng-container *ngIf="task.additional_text == 'therapy'; else elseBlockTherapy">
                        - {{ "pages.task-management." + task.customs.therapy_type | translate}}
                      </ng-container>
                      <ng-template #elseBlockTherapy>
                        - {{ "generic.session" | translate}} {{ task.customs.session.evaluation_session }}
                      </ng-template>
                    </ng-container>
                    <ng-template #elseBlock>
                      {{ task.additional_text }}
                    </ng-template>
                  </mat-label>

                  <mat-form-field class="pb-2 mx-2 select-primary col-12" appearance="fill" *ngIf="task.editMode && !isAlexion()">
                    <mat-label *ngIf="!isAlexion()">{{ 'generic.level' | translate }}</mat-label>
                    <mat-label *ngIf="isAlexion()">{{ 'generic.type' | translate }}</mat-label>
                    <mat-select [(ngModel)]="task.additional_text" required>
                      <mat-option [value]="option.value"
                                  *ngFor="let option of additional_text_options">{{ option.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="ml-2 input-primary col-12" *appHideIfOrganization="'alexion,enel'">
                    <input matInput placeholder="{{'generic.description' | translate}}" [(ngModel)]="task.description"
                           [readonly]="!task.editMode" required>
                  </mat-form-field>
                  <mat-form-field class="pb-2 select-primary col-12" appearance="fill"
                                  *ngIf="currentOrganization?.customs?.profile == 'enel'">
                    <mat-label>{{ 'pages.task-management.component.description' | translate }}</mat-label>
                    <mat-select class="color-default" [(ngModel)]="task.description" [disabled]="!task.editMode" required>
                      <mat-option *ngFor="let sec of allSections" [value]="sec">{{ sec }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="pb-2 select-primary col-12" appearance="fill"
                                  *ngIf="currentOrganization?.customs?.profile == 'enel'">
                    <mat-label>{{ 'pages.task-management.component.itemid' | translate }}</mat-label>
                    <mat-select class="color-default" [(ngModel)]="task.item_id" [disabled]="!task.editMode"
                                [required]="task.additional_text == 'level project'">
                      <mat-option></mat-option>
                      <mat-option *ngFor="let itid of filerItemBySection(task.description)"
                                  [value]="itid.id">{{ itid.text }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="mx-2 input-primary col-12" appearance="fill" *ngIf="!isAlexion() || (isAlexion() && task.additional_text === 'therapy')">
                    <mat-label>{{ 'pages.task-management.component.action' | translate }}</mat-label>
                    <textarea matInput [(ngModel)]="task.action" [readonly]="!task.editMode" required></textarea>
                  </mat-form-field>
                  <ng-container *ngIf="isAlexion(); else elseBlock">
                    <mat-form-field class="mx-2 input-primary col-12 my-2" appearance="fill">
                    <textarea matInput placeholder="{{'generic.note' | translate}}"
                              [(ngModel)]="task.customs.note" [readonly]="!task.editMode"></textarea>
                    </mat-form-field>
                  </ng-container>
                  <ng-template #elseBlock>
                    <mat-form-field class="mx-2 input-primary col-4 my-2">
                      <input matInput placeholder="{{'pages.task-management.component.responsible' | translate}}"
                             [(ngModel)]="task.assigned_user_name" [readonly]="!task.editMode">
                    </mat-form-field>
                  </ng-template>

                  <ng-container *ngIf="isAlexion() && task.additional_text === 'visit'">
                    <mat-form-field class="datepicker-primary col-4" appearance="fill" (click)="visit_date.open()">
                      <mat-label>{{ 'pages.task-management.component.visit-date' | translate }}</mat-label>
                      <input matInput [matDatepicker]="visit_date" [readonly]="!task.editMode" [(ngModel)]="task.customs.visit_date"
                             class="color-default ng-trim-ignore" disabled>
                      <mat-datepicker-toggle matSuffix [for]="visit_date">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #visit_date [disabled]="!task.editMode"></mat-datepicker>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="!isAlexion() || (isAlexion() && task.additional_text === 'therapy')">
                    <mat-form-field class="datepicker-primary col-4" appearance="fill" (click)="start_date.open()"
                                    *ngIf="currentOrganization?.customs?.profile != 'enel'">
                      <mat-label>{{ 'pages.task-management.component.start-date' | translate }}</mat-label>
                      <input matInput [matDatepicker]="start_date" [readonly]="!task.editMode" [(ngModel)]="task.start_date"
                             class="color-default ng-trim-ignore" disabled>
                      <mat-datepicker-toggle matSuffix [for]="start_date">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #start_date [disabled]="!task.editMode"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="datepicker-primary col-4" appearance="fill" (click)="end_date.open()">
                      <mat-label>{{ 'pages.task-management.component.end-date' | translate }}</mat-label>
                      <input matInput [matDatepicker]="end_date" [readonly]="!task.editMode" [(ngModel)]="task.end_date"
                             class="color-default ng-trim-ignore" disabled>
                      <mat-datepicker-toggle matSuffix [for]="end_date">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #end_date [disabled]="!task.editMode"></mat-datepicker>
                    </mat-form-field>
                  </ng-container>

                  <!-- <mat-checkbox class="abs-tr" name="check_completed_{{i}}" [checked]="task.has_completed == true" [disabled]="true" ></mat-checkbox> -->

                  <div class="row mx-auto flex-row-reverse" *ngIf="!task.editMode">
                    <button *ngIf="!isAlexion()" mat-icon-button class="mr-2" color="warn" [disableRipple]="true" (click)="deleteTask(task)"
                            matTooltip="{{'tooltip.delete' | translate}}" [matTooltipPosition]="'above'">
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                    <button mat-icon-button [disableRipple]="true" (click)="modifyTask(task, true)"
                            matTooltip="{{'tooltip.edit' | translate}}" [matTooltipPosition]="'above'"><i
                      class="icon-edit main-color {{ isAlexion() ? 'mr-2' : '' }}"></i></button>
                    <ng-container *appHideIfOrganization="'alexion'">
                      <button *ngIf="task.has_completed == true" mat-stroked-button
                              class="rounded-pill border-success text-success" (click)="checkCompletedTask(task, false)">
                        <fa-icon [icon]="faCheck"></fa-icon>
                        {{ 'generic.done' | translate }}
                      </button>
                      <button *ngIf="task.has_completed == false" mat-stroked-button class="rounded-pill"
                              (click)="checkCompletedTask(task, true)">
                        <fa-icon [icon]="faCheck"></fa-icon>
                        {{ 'generic.not-done' | translate }}
                      </button>
                    </ng-container>
                  </div>
                  <div class="row mx-auto flex-row-reverse" *ngIf="task.editMode">
                    <button *ngIf="task.id" mat-flat-button color="primary" class="mr-2"
                            (click)="modifyTask(task, false)">{{ 'generic.buttons.update' | translate }}
                    </button>
                    <button *ngIf="!task.id" mat-flat-button color="primary" class="mr-2"
                            (click)="saveNewTask(task)">{{ 'generic.buttons.save' | translate }}
                    </button>
                    <button *ngIf="(!task.id || task.editMode) && !isAlexion()" mat-flat-button color="warn" class="mr-2"
                            (click)="cancelEditing(task)">{{ 'generic.buttons.cancel-and-back' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>

        <!-- Pulsante di aggiunta Task -->
        <!-- <div class="container" *ngIf="decisionPlan?.id && decisionPlan?.tasks && decisionPlan.tasks.length > 1">
          <div class="row">
              <button class="col-12 big-button" mat-raised-button (click)="addTask()">
                  <i class="icon-big_add"></i>
                  {{'components.management-plan.add-task' | translate}}
              </button>
          </div>
        </div> -->

      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>


<!-- Buttons -->
<div class="buttons-container container py-4">
  <button class="button-primary float-left" mat-button [disabled]="loading" [routerLink]="['../show']">
    <i class="icon-Backward-arrow-small"></i>
    {{ 'generic.buttons.go-back' | translate }}
  </button>
</div>

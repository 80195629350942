import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {OrganizationService} from 'src/app/services/organization.service';
import {ToastService} from 'src/app/services/general/toast.service';
import {ModalAddAttachmentComponent} from 'src/app/modals/modal-add-attachment/modal-add-attachment.component';
import {UserService} from 'src/app/services/user.service';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ModalUserPermissionComponent} from 'src/app/modals/modal-user-permissions/modal-user-permissions.component';

import {faLock, faExclamationTriangle, faTrash, faTrashRestore} from '@fortawesome/free-solid-svg-icons';
import {AlertService} from 'src/app/services/general/alert.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {
  ModalYourOrganizationsComponent
} from '../../../modals/modal-your-organizations/modal-your-organizations.component';
import { ModalResetPasswordComponent } from 'src/app/modals/modal-reset-password/modal-reset-password.component';
import { ModalChangePasswordComponent } from 'src/app/modals/modal-change-password/modal-change-password.component';

@Component({
  selector: 'app-single-user-management',
  templateUrl: './single-user-management.component.html',
  styleUrls: ['./single-user-management.component.scss']
})
export class SingleUserManagementComponent implements OnInit {

  constructor(private router: Router, private alertService: AlertService, private toast: ToastService, private alert: AlertService, private activeRoute: ActivatedRoute, private organizationService: OrganizationService, public userService: UserService, private dialog: MatDialog, private translateService: TranslateService) {
  }

  faLock = faLock;
  faExclamationTriangle = faExclamationTriangle;

  private _activeRouteSubscription: Subscription;

  loading = false;
  innerWidth: any;

  user: any;
  // uso per capire se sto aggiungendo utente a decisions oppure se sto creando su server oauth
  isUserCreation: boolean;
  organization_id: any;
  organization_user_id: any;
  organization_roles: any = [];
  currentLang = 'it';

  modelUser: any = {
    // ? Campi di input
    first_name: null,
    last_name: null,
    roles: [] = [],
    // cardRoles : [{}],
    email: null,
    phone: null,
    secondary_email: null,
    avatar_link: null,
    order_number: null,
  };

  documentsPreparedData = [];

  displayedColumns: string[] = ['name', 'version', 'publication_date', 'actions'];
  dataSource: MatTableDataSource<unknown>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  permissions = [];

  protected readonly faTrash = faTrash;
  protected readonly faTrashRestore = faTrashRestore;

  ngOnInit(): void {

    this.innerWidth = window.innerWidth;

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {

      this.organization_id = routeParams.id_organization;
      this.organization_user_id = routeParams.id_user_organization;
      const user_id = routeParams.id;

      if (typeof user_id !== 'undefined') {
        this.organizationService.getOrganizationUser(this.organization_id, user_id, (result) => {
          // this.modelUser.id = result.user.id
          this.modelUser.first_name = result.user.name;
          this.modelUser.email = result.user.email;

          result.user?.date_user_creation ? this.isUserCreation = false : this.isUserCreation = true;

          if (this.isUserCreation && typeof this.organization_user_id === 'undefined') {
            this.organization_user_id = user_id;
          }
        });
      }

      // this.organization_user_id !== 'new' poichè il parametro può assumere anche il valore new faccio eseguire la init solo se non vale new
      if (this.organization_id && this.organization_user_id && this.organization_user_id !== 'new') {
        this.init();
      }
    });

    //  recupero tutte le informazioni dei ruoli dell'azienda
    // this.organizationService.allOrganizationRoles(this.organization_id, (result) => {
    //   this.organization_roles = result;
    // })
  }


  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription) {
      this._activeRouteSubscription.unsubscribe();
    }
  }

  public getOrganizationService() {
    return this.organizationService;
  }

  init() {

    //  recupero le informazioni dell'utente corrente
    this.getUser();

    this.userService.userInfo.organization_user.forEach((organization_user) => {
      if (this.userService.getCurrentOrganization().id === organization_user.organization_id) {
        this.permissions.push(...organization_user.permissions);
      }
    });

    if (this.isAlexion){
      const current_org_usr = this.userService.getCurrentOrganizationUser();
      const customs  = current_org_usr?.customs;
      this.modelUser.order_number = customs?.order_number;
    }

    this.initDocumentsDataSource();
    this.documentsPreparedData = [...this.dataSource.data];
  }

  initDocumentsDataSource(): any {
    this.dataSource = new MatTableDataSource(this.userAcceptedDocuments);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  canDeleteOrganizationUser() {
    if (this.user && this.permissions) {
      const updateOrganizationPermission = 'organization.update';

      const hasUpdateOrganizationPermission = this.permissions.some((permission) => {
        return permission.name === updateOrganizationPermission;
      });

      return this.user.id !== hasUpdateOrganizationPermission;
    } else {
      return false;
    }
  }

  deleteOrganizationUser() {
    this.alertService.showQuestionOnScreen('warning', this.translateService.instant('generic.warning'), this.translateService.instant('pages.user.single-user-management.component.delete-user-alert-description'), () => {
    this.loading = true;
    this.organizationService.deleteOrganizationUser(this.organization_id, this.organization_user_id, () => {
      this.loading = false;
      this.toast.success(
        this.translateService.instant('generic.success'),
        this.translateService.instant(
          'pages.user.single-user-management.component.delete-user-successfully'
        )
      );
      this.router.navigate(['/organization/' + this.organization_id + '/user']);
    }, (error) => {
      this.loading = false;
    });
    }, () => {});
  }

  get isGuestUser() {
    return this.userService.getCurrentOrganizationUser().role === 'GUEST';
  }

  /** Recupera le informazioni dell'utente corrente */
  getUser() {
    if (!this.isGuestUser){

      this.loading = true;

      this.organizationService.getOrganizationUser(this.organization_id, this.organization_user_id, (result) => {

        this.user = result;
        this.modelUser.id = this.user.id;
        this.modelUser.first_name = this.user.user?.details?.first_name;
        this.modelUser.last_name = this.user.user?.details?.last_name;
        this.modelUser.email = this.user.user.email;
        this.modelUser.originalRoles = this.user.roles;
        this.modelUser.details = this.user.user.details;
        if (this.user.user.curricula) {
          this.modelUser.curricula = this.user.user.curricula;
        }

        const customs = result.customs
        this.modelUser.order_number = customs?.order_number;

        // if (this.user.roles.length > 0)
        //   this.modelUser.cardRoles = [];

        //  TODO_MICHELE: da sistemare i ruoli hanno un concetto diverso adesso
        // this.user.roles.forEach(role => {
        //   if (role.role)
        //     this.modelUser.cardRoles.push({role_id: role.role.id});
        // });

        //  cerco il numero di telefono primario
        const phone = this.user.user.contacts.find(x => x.type == 'PRIMARYPHONE');
        if (phone) {
          this.modelUser.phone = phone.value;
        }

        //  cerco la mail secondaria
        const secondary_email = this.user.user.contacts.find(x => x.type == 'SECONDARYMAIL');
        if (secondary_email) {
          this.modelUser.secondary_email = secondary_email.value;
        }

        // this.showRoleAndPermission();

        this.loading = false;
      }, (error) => {
        this.loading = false;
      });
    }else{
      this.modelUser.first_name = this.userService?.userInfo?.name;
      this.modelUser.email = this.userService?.userInfo?.email;
    }
  }


  showRoleAndPermission() {

    const dialogRef = this.dialog.open(ModalUserPermissionComponent, {
      panelClass: this.innerWidth < 860 ? 'fullscreen-dialog' : 'small-dialog',
      data: {
        organization_user: this.user,
        user: this.modelUser
      },
    });
  }


  onSubmit(form: NgForm) {

    if (this.modelUser.first_name == null) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.user.single-user-management.component.error-name'));
      return;
    }

    if (this.modelUser.last_name == null) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.user.single-user-management.component.error-surname'));
      return;
    }

    if (this.modelUser.email == null) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.user.single-user-management.component.error-email'));
      return;
    }

    // this.cleanRoles();

    if (!form.form.valid) {
      return;
    }

    //  aggiorno l'utente
    if (this.modelUser.id && !this.isUserCreation) {
      this.updateUser();
      return;
    }

    this.saveNewUser();
  }


  saveNewUser() {
    //  salvo il nuovo utente
    this.loading = true;
    this.organizationService.saveOrganizationUser(this.organization_id, this.modelUser, (result) => {
      this.toast.success(this.translateService.instant('generic.user'), this.translateService.instant('pages.user.single-user-management.component.success-save-user'));
      this.loading = false;

      //  inserisco un messaggio se l'utente esistente aveva già delle informazioni inserite
      if (result && result.already_exists && result.already_exists == true) {
        //  TODO_TRADUZIONE:
        this.alert.showAlert(this.translateService.instant('generic.warning'), this.translateService.instant('Alcune informazioni dell\'utente sono state sovrascritte perchè già presenti in piattaforma'));
      }

      if (!this.isUserCreation) {
        this.router.navigate(['../'], {relativeTo: this.activeRoute});
      }
    }, (error) => {
      this.loading = false;
      this.toast.error(this.translateService.instant('generic.user'), this.translateService.instant('pages.user.single-user-management.component.error-save-user'));
    });
  }


  updateUser() {
    //  salvo il nuovo utente
    this.loading = true;
    this.organizationService.updateOrganizationUser(this.organization_id, this.organization_user_id, this.modelUser, (result) => {
      this.toast.success(this.translateService.instant('generic.user'), this.translateService.instant('pages.user.single-user-management.component.success-update-user'));
      this.loading = false;
      this.getUser();
    }, (error) => {
      this.loading = false;
      this.toast.error(this.translateService.instant('generic.user'), this.translateService.instant('pages.user.single-user-management.component.error-update-user'));
    });
  }

  /** Pulisce l'inserimento dei ruoli prima di inviarli */
  cleanRoles() {

    //  rimuovo i valori vuoti
    this.modelUser.cardRoles = this.modelUser.cardRoles.filter(x => x.role_id);

    //  rimuovo i valori duplicati
    this.modelUser.cardRoles = this.modelUser.cardRoles.filter((thing, index) => {
      const _thing = JSON.stringify(thing);
      return index === this.modelUser.cardRoles.findIndex(obj => {
        return JSON.stringify(obj) === _thing;
      });
    });

    this.modelUser.roles = this.modelUser.cardRoles.map(value => value.role_id);
  }


  /**
   * Cambio di ruolo nel box
   * @param index
   * @returns
   */
  onChangeRole(index) {

    //  se l'utente non è già stato creato non devo fare nulla
    if (!this.modelUser.id) {

      if (this.checkIfRoleExist(this.modelUser.cardRoles[index].role_id)) {
        this.modelUser.cardRoles[index] = {role_id: undefined};
        this.toast.alert(this.translateService.instant('generic.roles.role'), this.translateService.instant('pages.user.single-user-management.component.alert-role-user'));
      }
      return;
    }

    //  se sto eliminando un ruolo
    if (!this.modelUser.cardRoles[index].role_id) {

      if (typeof this.modelUser.originalRoles[index] !== 'undefined') {
        this.deleteRole(this.modelUser.originalRoles[index].organization_role_id);
      }
    }
    //  sto cambiando il ruolo
    else {

      //  ruolo già esistente
      if (this.checkIfRoleExist(this.modelUser.cardRoles[index].role_id)) {
        this.toast.alert(this.translateService.instant('generic.roles.role'), this.translateService.instant('pages.user.single-user-management.component.alert-role-user'));

        //  controllo se provavo a sostituire un ruolo o ad aggiungerne uno nuovo
        if (typeof this.modelUser.originalRoles[index] !== 'undefined') {
          this.modelUser.cardRoles[index] = {role_id: this.modelUser.originalRoles[index].organization_role_id};
        }
        else {
          this.modelUser.cardRoles[index] = {role_id: undefined};
        }

      }
      //  ruolo non presente
      else {

        if (typeof this.modelUser.originalRoles[index] !== 'undefined') {
          //  sostituisco il ruolo
          this.replaceRole(this.modelUser.originalRoles[index].organization_role_id, this.modelUser.cardRoles[index].role_id);
        } else {
          //  aggiungo un nuovo ruolo
          this.addRole(this.modelUser.cardRoles[index].role_id);
        }
      }
    }

  }


  /** Controlla che il ruolo è già presente */
  checkIfRoleExist(role_id) {

    if (this.modelUser.cardRoles.filter(x => x.role_id == role_id).length > 1) {
      return true;
    }

    return false;
  }

  /** Aggiunge un nuovo ruolo */
  addRole(new_role_id) {

    this.loading = true;
    this.organizationService.addUserRole(this.organization_id, this.organization_user_id, new_role_id, (success) => {

      this.toast.success(this.translateService.instant('generic.roles.role'), this.translateService.instant('pages.user.single-user-management.component.success-added-role'));
      this.loading = false;

      this.getUser();
    }, () => {

      this.toast.error(this.translateService.instant('generic.roles.role'), this.translateService.instant('pages.user.single-user-management.component.error-update-role'));
      this.loading = false;
    });
  }


  /** Aggiorno un ruolo con un un'altro */
  replaceRole(old_role_id, new_role_id) {

    this.loading = true;
    this.organizationService.replaceUserRole(this.organization_id, this.organization_user_id, old_role_id, new_role_id, (result) => {

      this.toast.success(this.translateService.instant('generic.roles.role'), this.translateService.instant('pages.user.single-user-management.component.success-update-role'));
      this.loading = false;

      this.getUser();
    }, () => {

      this.toast.error(this.translateService.instant('generic.roles.role'), this.translateService.instant('pages.user.single-user-management.component.error-update-role'));
      this.loading = false;
    });
  }

  resetOrganizationUser() {
    this.alertService.showQuestionOnScreen('warning', this.translateService.instant('generic.warning'), this.translateService.instant('pages.user.single-user-management.component.reset-user-alert-description'), () => {
      this.loading = true;
      this.organizationService.resetOrganizationUser(this.organization_id, this.user.id, () => {
        this.loading = false;
        this.user.deleted_at = null;
        this.toast.success(
          this.translateService.instant('generic.success'),
          this.translateService.instant(
            'pages.user.single-user-management.component.reset-user-successfully'
          )
        );
      }, (error) => {
        this.loading = false;
      });
    }, () => {});
  }


  /** Cancello un ruolo specifico */
  deleteRole(role_id) {

    this.loading = true;
    this.organizationService.deleteUserRole(this.organization_id, this.organization_user_id, role_id, (result) => {

      this.toast.success(this.translateService.instant('generic.roles.role'), this.translateService.instant('pages.user.single-user-management.component.success-delete-role'));
      this.loading = false;

      this.getUser();
    }, () => {

      this.toast.error(this.translateService.instant('generic.roles.role'), this.translateService.instant('pages.user.single-user-management.component.error-delete-role'));
      this.loading = false;
    });
  }


  onClickAddRole() {

    this.modelUser.cardRoles.push({});
  }

  onClickRemoveRole(index) {

    //  controllo se sto rimuovendo un ruolo per un utente già creato
    if (this.modelUser.id) {

      //  leggo il ruolo che sto rimuovendo, se è un ruolo già assegnato all'utente lo rimuovo con una chiamata api altrimenti lo lascio stare
      if (this.modelUser.cardRoles[index]?.role_id) {
        this.deleteRole(this.modelUser.cardRoles[index]?.role_id);
        return;
      }
    }

    this.modelUser.cardRoles.splice(index, 1);
    if (this.modelUser.cardRoles.length == 0) {
      this.onClickAddRole();
    }
  }

  // aggiungo foto profilo utente
  onClickUploadPhoto() {

    let uploadUrl = 'upload/tmp-user-photo';
    if (this.modelUser.id) {
      uploadUrl = `upload/user/${this.modelUser.id}/photo`;
    }

    const dialogRef = this.dialog.open(ModalAddAttachmentComponent, {
      panelClass: 'small-dialog',
      data: {
        title: 'Upload Photo',
        attachmentType: 'FILE',
        maxUploadsFile: 1,
        user_id: this.modelUser.id,
        uploadUrl
      }
    });

    dialogRef.afterClosed().subscribe(attachmentData => {

      if (attachmentData?.attachmentObject) {
        if (this.modelUser.id) {
          this.toast.success(this.translateService.instant('generic.user'), this.translateService.instant('pages.user.single-user-management.component.success-added-photo'));
          this.init();
        } else {
          this.modelUser.attachment_files = attachmentData.attachmentObject[0];
          if (this.modelUser?.details) {
            this.modelUser.details.avatar_link = attachmentData.attachmentObject[0].link;
          } else {
            this.modelUser.details = {avatar_link: attachmentData.attachmentObject[0].link};
          }
        }
      }
    });
  }

  // reset password utente
  onClickResetPassword() {
    const dialogRef = this.dialog.open(ModalResetPasswordComponent, {
      panelClass: 'medium-dialog',
      data: {
        user: this.user
      }
    });

    dialogRef.afterClosed().subscribe(attachmentData => {
      if (attachmentData?.event && attachmentData?.event && attachmentData?.event?.event == 'added') {
        this.init();
      }
    });
  }

  // user's password change
  onClickChangePassword() {
    const dialogRef = this.dialog.open(ModalChangePasswordComponent, {
      panelClass: 'small-dialog',
      data: {
        user: this.user
      }
    });

    dialogRef.afterClosed().subscribe(attachmentData => {
      if (attachmentData?.event && attachmentData?.event && attachmentData?.event?.event == 'added') {
        this.init();
      }
    });
  }


  useLanguage(language: string): void {
    this.translateService.use(language);
    this.currentLang = language;
    this.userService.saveLanguage(language, (result) => {
    });
  }

  get userAcceptedDocuments() {
    return this.userService.userInfo.acceptedDocuments;
  }

  get isLoggedUser() {
    return this?.userService?.userInfo?.id === this?.user?.user_id;
  }

  revokeDocument(id) {
    this.loading = true;
    this.alert.showQuestionOnScreen('warning', this.translateService.instant('generic.warning'), this.translateService.instant('pages.user.user-profile.component.revoke-document-alert'), () => {
      this.userService.revokeDocument(id, (result) => {
        this.loading = false;
        location.href = '/';
      }, () => {
        this.loading = false;
      });
    }, () => {});
  }

  openDialogYourOrganizations(organizations = undefined) {
    this.dialog.open(ModalYourOrganizationsComponent, {
      panelClass: 'medium-dialog',
      data: {
        organizations,
        // certificate : certificate
      }
    });
  }

  get isAlexion() {
    const current_org_usr = this.userService.getCurrentOrganizationUser();
    const customs  = current_org_usr?.customs;
    return customs?.profile == "alexion";
  }
}
